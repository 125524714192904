<template>
  <div class="content-container pt-0">
    <div class="landing-header">
        <img class="img-center" src="../assets/images/landing-banner.png" alt="">
    </div>

    <div class="landing-header-title align-end gray-100 mb-0">
      <div class="container flex flex-col flex-auto mlr-20 max-w1440">
        <h1 class="xl">Foundations</h1>
         <p class="mt-0">
          Foundations are fundamental elements that provide structure and
          consistency to citizen digital experiences.
        </p>
      </div>
    </div>

    <!-- <section>
      <div class="content-band mt-20 mb-10">
        <h2 class="">What are foundations?</h2>
        <p>
          Foundations are fundamental elements that provide structure and
          consistency to citizen digital experiences.
        </p>
      </div>
    </section> -->

    <section>
      <div class="content-band mt-10 flex flex-wrap gap-20">
        <NewTile
          v-for="(element, index) in tileData"
          :key="index"
          :tile="element"
          page="foundations"
        />
      </div>
    </section>
  </div>
</template>

<script>
import NewTile from "../components/NewTile";
export default {
  name: "Foundations",
  data: function() {
    return {
      tileData: [
        {
          icon: "photo-tile",
          title: "Photography",
          description:
            "Defining the preferred photographic style for use in Ohio websites and applications.",
        },
        {
          icon: "illus",
          title: "Illustration",
          description:
            "Guidelines for using illustration to communicate and clarify abstract concepts and ideas.",
        },
        {
          icon: "iconography",
          title: "Icons",
          description:
            "Guidelines for using icons draw attention to actions or information.",
        },
        {
          icon: "typo",
          title: "Typography",
          description:
            "Defining the style and appearance of text for State of Ohio digital products.",
        },
        {
          icon: "color",
          title: "Color",
          description:
            "Guidance around using color to create consistent experiences across the State.",
        },
        {
          icon: "layout",
          title: "Layout & Spacing",
          description:
            "Defining layout patterns and spacing controls for responsive experiences.",
        },
      ],
    };
  },
  components: {
    // Tile,
    NewTile,
  },
};
</script>
